<template>
	<div class="container">
		<div class="top">快捷回复</div>

		<div class="filter-item">
			<el-button type="primary" @click="handleAdd(0)">添加</el-button>
		</div>

		<div class="table-content">
			<el-table :data="tableDataList" v-loading="loading" style="width: 100%">
				<el-table-column prop="Key" label="关键字"></el-table-column>
				<el-table-column label="回复内容" width=" 480px">
					<template slot-scope="scope">
						<el-tooltip class="item-tooltip comment-reply-item-tooltip" effect="light" :content="scope.row.Content" placement="top-start">
							<div class="content-text">
								{{scope.row.Content}}
							</div>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="Guess" label="是否跟随欢迎语">
					<template slot-scope="scope">
						<span v-if="scope.row.Guess">是</span>
						<span v-else>否</span>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column prop="Stock" label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="handleAdd(1,scope.row)">编辑</el-button>
						<el-button type="text" @click="handleTableDelete(scope.row.Id)" style='color:#F56C6C'>删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="page.total" style="margin:20px 0;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>

		<!-- edit 、add 弹框 -->
		<el-dialog :title="`${editType}快捷回复`" :visible.sync="editShow" width="800px" class="dialog">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">

				<el-form-item label="关键字:" prop="keyWord">
					<el-input v-model="ruleForm.keyWord" placeholder="最多输入18字" maxlength="18" style="width:300px;"></el-input>
				</el-form-item>


				<el-form-item label="回复内容:" prop="content">
					<el-input v-model="ruleForm.content" type="textarea" maxlength="200" placeholder="最多输入200个字" :rows="7" style="width:470px;"></el-input>
				</el-form-item>

				<el-form-item label="跟随设置:" prop="follow">
					<el-checkbox v-model="ruleForm.follow">猜你想问（跟随欢迎语显示）</el-checkbox>
				</el-form-item>

				<el-form-item label="标题:" prop="title" v-if="ruleForm.follow">
					<el-input v-model="ruleForm.title" placeholder="最多输入18字" maxlength="18" style="width:300px;"></el-input>
				</el-form-item>

				<div style="text-align:center;margin-top:20px;">
					<el-button class="button" style="width:150px" @click="handleCloseEdit">关闭</el-button>
					<el-button class="button" style="width:150px" @click="handleSaveEdit('ruleForm')" type="primary" :loading="loading">保存</el-button>
				</div>

			</el-form>

		</el-dialog>
	</div>
</template>

<script>
	import {
		xjmallplatformgateway,
	} from '@/api/goods'
	export default {
		data() {
			return {
				loading: false,
				searchKey: '',
				tableDataList: [],
				page: {
					total: 1,
					size: 20,
					current: 1
				},
				editType: '添加',
				editShow: false,
				ruleForm: {
					keyWord: '',
					content: '',
					follow: false,
					title: ''
				},
				rules: {
					keyWord: [{
						required: true,
						message: '请输入关键字',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请输入回复内容',
						trigger: 'blur'
					}],
					title: [{
						required: true,
						message: '请输入标题',
						trigger: 'blur'
					}],
				}
			}
		},
		created() {
			this.getData()
		},
		methods: {
			//初始化
			async getData() {
				try {
					this.loading = true
					let data = {
						ApiPath: '/ChatAutoReply/index',
						RequestBody: {
							OrderByDesc: "Id",
							Take: this.page.size,
							Skip: (this.page.current - 1) * this.page.size
						}
					}
					let result = await xjmallplatformgateway(data)
					this.tableDataList = result.Result.Results
					this.page.total = result.Result.Total
					this.tableDataList.map(item => {
						item.Content = this.removeHTMLTag(item.Content)
						return item
					})
				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			removeHTMLTag(html) {
				var htmlTagReg = /<(\/)?[^>].*?>/g;
				return html.replace(htmlTagReg, '');
			},

			//添加、编辑
			handleAdd(type, val) {

				if (type) {
					this.editType = '编辑'
					this.ruleForm = {
						Id: val.Id,
						keyWord: val.Key,
						content: val.Content,
						follow: val.Guess,
						title: val.Title
					}
				} else {
					this.editType = '添加'
					this.ruleForm = {
						keyWord: '',
						content: '',
						follow: false,
						title: ''
					}
				}

				this.editShow = true
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
			//删除
			// table删除
			handleTableDelete(id) {
				this.$confirm('是否确认删除快捷回复？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					this.loading = true
					console.log(id, '删除')
					let data = {
						ApiPath: '/ChatAutoReply/delete',
						RequestBody: {
							Id: id
						}

					}
					let result = await xjmallplatformgateway(data)
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						this.getData()
						this.loading = false
					}
				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
					this.loading = false
				}).finally(() => {
					this.loading = false
				})
			},
			//关闭edit
			handleCloseEdit() {
				this.editShow = false
			},
			//保存edit
			handleSaveEdit(formName) {
				this.$refs[formName].validate(async(valid) => {
					if (valid) {
						try {
							this.loading = true
							let ChatAutoReply = {
								ChatAutoReply: {
									Id: this.ruleForm.Id,
									Content: this.ruleForm.content,
									Key: this.ruleForm.keyWord,
									Title: this.ruleForm.title,
									Guess: this.ruleForm.follow
								}
							}
							let data = {
								ApiPath: '/ChatAutoReply/update',
								RequestBody: ChatAutoReply

							}
							let result = await xjmallplatformgateway(data)
							if (result.IsSuccess) {
								this.$message({showClose: true,
									type: 'success',
									message: '保存成功!'
								});
								this.getData()
								this.editShow = false
							}

						} catch (e) {
							console.log(e)
						} finally {
							this.loading = false
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
			handleSizeChange(val) {
				this.page.size = val
				this.getData()
			},
			handleCurrentChange(val) {
				this.page.current = val
				this.getData()
			}
		}
	}
</script>

<style lang="less" scoped>
	.container {
		padding: 10px;
		background-color: #fff;

		.top {
			line-height: 40px;
			border-bottom: 1px solid #ddd;
		}

		.filter-item {
			margin-left: 10px;
			padding: 20px 10px;
		}

		.table-content {
			overflow: hidden;
		}

		.content-text {
			width: 470px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}

		.dialog {
			width: 100%;

			::v-deep .el-dialog__header {
				border-bottom: 1px solid #ddd;
			}
		}
	}
</style>
<style>
	.el-tooltip__popper {
		max-width: 530px;
	}
</style>
